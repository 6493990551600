<template>
  <a-card :bordered="false">
    <div class="header">
      <a-input
        v-model="form.taskName"
        placeholder="请输入查找文件名"
        style="width: 220px"
        class="mr-10"
      />
      <a-range-picker
        :editable="false"
        placement="right-start"
        :placeholder="['请选择导入时间', '请选择导出时间']"
        v-model="form.timeRange"
        style="width: 300px"
        class="mr-10"
      ></a-range-picker>
      <a-button type="primary" @click="search" class="mr-10">查询</a-button>
    </div>

    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :rowKey="(record) => record.id"
      :loading="loading"
      :scroll="{ x: true }"
      size="default"
    >
    </a-table>

    <a-pagination
      style="
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 10px 10px 5px 0;
      "
      size="small"
      v-model="pageNum"
      :total="total"
      :showTotal="(total) => `共 ${total} 条`"
      showSizeChanger
      showLessItems
      :pageSize.sync="pageSize"
      @change="changePage"
      @showSizeChange="
        () => {
          this.pageNum = 1;
          this.changePage();
        }
      "
    />
  </a-card>
</template>
<script>
import util from '@/libs/util';
import Cookies from 'js-cookie';
import api from '@/api';
import moment from 'moment'
export default {
  name: 'downloadfile',

  data() {
    return {
      // 操作类型: (增加: 0, 编辑: 1, 导入: 2, 删除: 3)
      loading: false,
      columns: [
        {
          title: 'ID', // 每个发票的唯一标识，同一主订单下的亚美开票、第三方开票的ID也不同
          dataIndex: 'id',
          width: '80px',
          align: 'center',
          ellipsis: true
        },
        {
          title: '文件名称',
          dataIndex: 'title',
          width: '150px',
          align: 'center',
          ellipsis: true
        },
        {
          title: '导入/导出时间',
          dataIndex: 'createTime',
          align: 'center',
          width: '200px',
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.createTime ? moment(record.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
          }
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          width: '200px',
          ellipsis: true,
          customRender: (text, record, index) => {
            if (record) {
              if (record.status == 1) {
                return '执行成功';
              } else if (record.status == 0) {
                return '正在执行';
              } else {
                return <span style="color: red;">执行失败</span>;
              }
            }
          }
        },
        {
          title: ' 数据总数',
          dataIndex: 'total',
          align: 'center',
          ellipsis: true
        },
        {
          title: ' 操作人',
          dataIndex: 'roleAccount',
          align: 'center',
          width: '100px',
          ellipsis: true
        },
        {
          title: '运行备注',
          dataIndex: 'remark',
          align: 'center',
          ellipsis: true
        },
        {
          title: '目前进度',
          width: '100px',
          dataIndex: 'execRatio',
          align: 'center',
          customRender: (text, record, index) => {
            if (record.status == 2) {
              return '--';
            } else {
              if (record.execRatio * 100 == 100) {
                return record.execRatio * 100 + '%';
              } else {
                return (
                  <div>
                    <p>{record.execRatio * 100 + '%'}</p>
                    <a onClick={() => this.updateExecRatio(record)}>更新进度</a>
                  </div>
                );
              }
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '80px',
          align: 'center',
          customRender: (text, record) => {
            const account = isNaN(record.roleAccount)
              ? Cookies.get('accountName')
              : Cookies.get('account');
            const isShow = account === record.roleAccount;
            // 0-进行中，1-成功，2-失败
            return (
              <div>
                {isShow && record.status != 1 ? (
                  <a
                    class="mr-10"
                    onClick={() => this.delExportRecord(record.id)}
                  >
                    删除
                  </a>
                ) : null}
                {isShow && record.status == 2 ? (
                  <a
                    class="mr-10"
                    onClick={() => this.retryDownLoad(record.id)}
                  >
                    导出重试
                  </a>
                ) : null}
                <a onClick={() => this.download(record.excelUrl)}>下载</a>
              </div>
            );
          }
        }
      ],
      data: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      form: {
        taskName: '', // 商品导入	支持模糊匹配
        pageSize: '', // 页大小	默认是每页30条数据
        timeRange: [],
        startTime: '', // 开始时间区间	秒为单位
        endTime: '', // 结束之间区间	秒为单位
        pType: '', // 0	0导入，1导出。不填默认是-1
        userId: '', // 100	管理后台账号ID
        account: '', // 988888	管理后台账号
        platApp: 5, // 2	管理后台：0 bgs，1 bbc后台，2商家后台，3 oms后台，4 oms大屏, 5 经销管理后台
        taskStatus: '' // 0	任务状态：0正在执行，1执行成功，2执行失败
      }
    };
  },
  methods: {
    updateExecRatio(row) {
      let _this = this;
      let params = {
        taskId: row.id
      };
      api.integratedManage
        .downloadExecRatio(params)
        .then(res => {
          if (res.code == this.successCode) {
            if (res.data.excelTask.execRatio) {
              row.execRatio = parseFloat(res.data.excelTask.execRatio).toFixed(
                2
              );
            }
            // 更新状态、数据总数、运行备注
            const excelTask = res.data?.excelTask || {};
            const { status, total, remark } = excelTask || {};
            row.status = status;
            row.total = total || '';
            row.remark = remark || '';
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    download(url) {
      if (url) {
        window.location.href = url;
      }
    },
    // 查询
    search() {
      this.pageNum = 1;
      this.getList();
    },
    // 导出重试
    retryDownLoad(taskId) {
      this.$confirm({
        content: `确定要重新导出当前的记录吗?`,
        centered: true,
        onOk: close => {
          api.integratedManage.retryTask({ taskId }).then(res => {
            if (res.code !== '00000') return this.$message.error(res.msg);
            this.$message.success('操作成功');
            this.getList();
            close();
          });
        }
      });
    },
    // 删除记录
    delExportRecord(id) {
      this.$confirm({
        content: `当前导出文件需要重新操作导出，是否确认删除?`,
        title: '删除后当前导出的记录将不存在',
        centered: true,
        onOk: close => {
          const params = {
            userId: Cookies.get('id'),
            taskId: id
          };

          if (!params.userId) return this.$message.error('找不到该用户');

          api.integratedManage.deleteFail(params).then(res => {
            if (res.code !== '00000') return this.$message.error(res.msg);
            this.$message.success('操作成功');
            this.getList();
            close();
          });
        }
      });
    },
    changePage() {
      this.getList();
    },
    getList() {
      if (this.form.timeRange.length > 0) {
        this.form.startTime = new Date(moment(this.form.timeRange[0]).format('YYYY-MM-DD 00:00:01')).getTime();
        this.form.startTime = new Date(moment(this.form.timeRange[1]).format('YYYY-MM-DD 23:59:59')).getTime();
      } else {
        this.form.startTime = '';
        this.form.endTime = '';
      }

      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        taskName: this.form.taskName, // 商品导入	支持模糊匹配
        startTime: this.form.startTime, // 开始时间区间	秒为单位
        endTime: this.form.endTime, // 结束之间区间	秒为单位
        // pType: this.form.pType,	// 0	0导入，1导出。不填默认是-1
        account: Cookies.get('account'), // 988888	管理后台账号
        platApp: this.form.platApp, // 2	管理后台：0 bgs，1 bbc后台，2商家后台，3 oms后台，4 oms大屏
        execService: 'xmall-youxuan-admin'
        // taskStatus: this.form.taskStatus,	// 0	任务状态：0正在执行，1执行成功，2执行失败
      };
      this.loading = true;
      api.integratedManage
        .downloadTaskList(util.filterParams(params))
        .then(res => {
          this.loading = false;
          if (res.code == this.successCode) {
            this.data = res.data.dataList;
            this.total = res.data.total;
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  activated() {
    this.getList();
  }
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
.mr-10 {
  margin-right: 10px;
}
.header {
  margin-bottom: 20px;
}
.table {
  margin-bottom: 10px;
}
</style>
